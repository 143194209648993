<template>
	<vue-excel-xlsx :columns="headers" :data="rows" :filename="name">
		<v-btn v-if="icon" icon><v-icon>mdi-download</v-icon></v-btn>
		<v-btn color="primary" v-else>{{ $t("buttons.download") }}</v-btn>
	</vue-excel-xlsx>
</template>

<style lang="less"></style>

<script type="text/javascript">
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from "vue";
Vue.use(VueExcelXlsx);

// renders a download button whih outputs an excel file of the provided data
// @group Downloads
export default {
	name: "Export",
	props: {
		// the headers - an array of { field, label } objects
		headers: { type: Array, required: true },
		// the data - an array of objects objects keyed by header fields
		rows: { type: Array, required: true },
		// whether to render this as an icon button
		icon: { type: Boolean, default: false },
		// the generated file name
		name: { type: String, default: "file" },
	},
};
//
</script>
"
